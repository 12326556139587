import { createSlice } from '@reduxjs/toolkit';
import {
  getSessionColumn,
  removeSessionColumn,
  setSessionColumn,
} from '../../Context/AuthSessionStorage';

// Month and year general settings
const setFilterLocal = () => {
  let getFilterLocal = sessionStorage.getItem('filterState');
  let parsed =
    getFilterLocal == undefined
      ? { month: '', year: '' }
      : JSON.parse(atob(getFilterLocal));
  return parsed;
};

const getInitialColumnState = () => {
  try {
    const sessionColumn = getSessionColumn();
    return sessionColumn?.length ? JSON.parse(sessionColumn) : [];
  } catch (error) {
    console.error('Error parsing column state:', error);
    return [];
  }
};

const initialState = {
  filter:
    sessionStorage.getItem('filterState') === undefined
      ? {
          month: new Date().toLocaleString('en-US', {
            month: 'long',
          }),
          year: new Date().getFullYear(),
        }
      : setFilterLocal(),
  column: getInitialColumnState(),
};

// let initialState =
// sessionStorage.getItem('filterState') === undefined
//   ? {
//       month: new Date().toLocaleString('en-US', {
//         month: 'long',
//       }),
//       year: new Date().getFullYear(),
//     }
//   : setFilterLocal();

export let filterState = createSlice({
  name: 'filterState',
  initialState,
  // initialState: {
  //   filter: initialState,
  //   column: [],
  // },
  reducers: {
    setFilterMonth(state, action) {
      state.filter.month = action.payload;
    },
    setFilterYear(state, action) {
      state.filter.year = action.payload;
    },
    setColumn(state, action) {
      state.column = action.payload;
      !action?.payload?.length
        ? removeSessionColumn()
        : setSessionColumn(action.payload);
    },
  },
});

export let { setFilterMonth, setFilterYear, setColumn } = filterState.actions;

export default filterState.reducer;

export let getfilterState = (state) => state?.filterState?.filter;
export let getColumnFilter = (state) => state?.filterState?.column;
