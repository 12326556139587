import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Label, CardBody, Card, Row, Col } from 'reactstrap';
import style from '../../Styles/AddUser.module.css';
import xlsx from '../../Assets/xlsx_icon.svg';
import pdf from '../../Assets/Adobe PDF.svg';
import { FaArrowLeft, FaRegEye } from 'react-icons/fa';
import {
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  message,
  Switch,
  InputNumber,
  Upload,
  Image,
  Checkbox,
} from 'antd';
import Department from '../../Api/Department';
import UserRole from '../../Api/UserRole';
import UserApi from '../../Api/UserApi';
import Designation from '../../Api/Designation';
import {
  GENDER,
  BG_STATUS,
  EMPLOYEE_TYPE,
  EMPLOYEE_MODE,
  SERVICE_STATUS,
  EMPLOYEE_STATUS,
  EMPLOYEE_EXIT_STATUS,
  PROBATION_STATUS,
  SETTLEMENT_STATUS,
  DateFormat,
  SETTLEMENT_ELGIBILITY_STATUS,
} from '../../constant/constant';
import TextArea from 'antd/es/input/TextArea';
import { toastSuccess, toastWarn, toastError } from '../Toast/ToastComponent';
import { useNavigate, useLocation, useParams } from 'react-router';
import dayjs from 'dayjs';
import { useAuth } from '../../Context/AuthContext';
import {
  ConsoleSqlOutlined,
  EyeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import AddContact from './AddContact';
import { MdDeleteOutline } from 'react-icons/md';
import { getUser } from '../../store/reducers/actionPermission.js';
import { useSelector } from 'react-redux';
import { usePageAccess } from '../../helpers/handlePageAccess.js';
import { lightGreen } from '@mui/material/colors';
import { useRef } from 'react';
import pdfViewer from '../../ReusableFn/pdfViewer.js';
import { UserRoleChangePopUp } from '../ReusableComponents/DeletePopup.js';

const { Option } = Select;
let imageUrldata = '';

let userRole;

function AddUserForm() {
  const pageAccess = usePageAccess();
  const data = useSelector(getUser);
  const { login, authState } = useAuth();
  const [userData, setUserData] = useState(null);
  const [fileList, setFileList] = useState({ files: [], baseFormat: [] });
  const [previewOpen, setPreviewOpen] = useState(false);
  const { edit_id } = useParams();
  const methods = useForm({
    defaultValues: {
      first_name: userData?.first_name || '',
      created_date: userData?.created_date
        ? dayjs(userData.created_date, DateFormat).format(DateFormat)
        : null,
      reportAuthorityID: userData?.reportAuthorityID || [],
      settlement_graduation: userData?.settlement_graduation
        ? userData?.settlement_graduation
        : null,
      // employmentStatus: userData?.employmentStatus
      //   ? userData?.employmentStatus
      //   : EMPLOYEE_STATUS[0].value,
      // employmentType: userData?.employmentType
      //   ? userData?.employmentType
      //   : EMPLOYEE_TYPE[1].value,

      additional_contacts: [],
      variable: null,
      defaultSendCredentials: true,
      allowNotification: true,
      disableLogin: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,

    watch,
  } = methods;

  const [settlementValue, employmentStatusValue, settlement_eligibility] =
    watch([
      'settlement_graduation',
      'employmentStatus',
      'settlement_eligibility',
    ]);
  const inputRef = useRef(null);

  let check_first_approver = watch('first_approvers');
  let check_second_approvers = watch('second_approvers');

  const [state, setState] = useState({
    departmentList: [],
    designationList: [],
    userRolesList: [],

    userAuthoriryList: [],
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      let formData = new FormData();
      for (let element in data) {
        if (
          element == 'first_approvers' ||
          element == 'second_approvers' ||
          element == 'reportAuthorityID' ||
          element == 'additional_contacts'
        ) {
          formData.append(
            element,
            data[element] ? JSON.stringify(data[element]) : '',
          );
        } else if (element == 'fileInfo') {
          let newFilterFile =
            fileList?.baseFormat?.filter((item) =>
              item.hasOwnProperty('filename'),
            ) || [];
          if (newFilterFile.length > 0) {
            formData.append(
              element,
              data[element]
                ? JSON.stringify(newFilterFile)
                : JSON.stringify([]),
            );
          }
        } else {
          formData.append(element, data[element]);
        }
      }

      if (!!fileList.files?.length) {
        for (let f of fileList.files) {
          formData.append('documents', f);
        }
      }

      // if (edit_id && !fileList.baseFormat?.length) {
      //   // formData.append('fileDelete', true);
      // }

      let response;

      if (edit_id) {
        // if (
        //   (userData.userRoleID == 2 ||
        //     data.userRoleID == 2 ||
        //     data.userRoleID == 1 ||
        //     userData.userRoleID == 1) &&
        //   userData.userRoleID !== data.userRoleID
        // ) {
        //   formData.append('roleidChanged', true);
        // }

        if (
          ([2].includes(userData.userRoleID) ||
            [2].includes(data.userRoleID)) &&
          userData.userRoleID !== data.userRoleID
        ) {
          formData.append('roleidChanged', true);
        }

        response = await UserApi.update(formData);
      } else {
        response = await UserApi.create(formData);
      }

      if (response.data.success) {
        toastSuccess(response.data.message);
        if (authState.id == data.id) {
          await login(response?.data?.result);
          navigate('/');
          window.location.reload();
          return;
        }
        navigate('/user');
      } else {
        toastError(response.data.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getDropDown = async () => {
    const [department, designation, userRole] = await Promise.all([
      Department.dropDown({}),
      Designation.dropDown({}),
      UserRole.dropDownUserRole({}),
    ]);

    const newState = {
      departmentList: department?.data?.result,
      designationList: designation?.data?.result,
      userRolesList: userRole?.data?.result,
    };

    if (!edit_id) {
      const authoriry = await UserApi.dropDownUsers({});
      newState.userAuthoriryList = authoriry?.data?.result;
    }

    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const getUserById = async (userId) => {
    try {
      const response = await UserApi.getUser(userId);
      if (response?.data?.success) {
        setUserData(response?.data?.result);

        userRole = response?.data?.result?.userRoleID;

        const authoriry = await UserApi.dropDownUsers({
          user_id: response?.data?.result?.id,
        });

        setState((prevState) => ({
          ...prevState,
          userAuthoriryList: authoriry?.data?.result,
        }));

        setFileList({
          files: [],
          baseFormat: response?.data?.result?.fileInfo || [],
        });
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const handleSecond_Approver = () => {
    if (!check_first_approver || !check_first_approver?.length) {
      setValue('second_approvers', null);
    }
  };

  useEffect(() => {
    handleSecond_Approver();
  }, [check_first_approver]);

  useEffect(() => {
    getDropDown();
    if (edit_id) {
      getUserById(edit_id);
    }
  }, []);

  useEffect(() => {
    if (data) {
      pageAccess(data, edit_id);
    }
  }, [data]);

  const handleImageChange = async (event) => {
    let fileLis = event.target.files;
    let objArr = [];
    let filesArr = [];
    for (let i = 0; i < fileLis.length; i++) {
      let file = fileLis[i];
      let totalSizeKB = file?.size / Math.pow(1024, 1);
      if (totalSizeKB > 10000) {
        toastWarn('Please Upload Below 10 MB', 'Invalid');
        return;
      }

      let obj = { type: file?.type, name: file?.name };
      if (file?.type.includes('image')) {
        obj['media_type'] = 'image';
      } else if (file?.type.includes('pdf')) {
        obj['media_type'] = 'pdf';
      } else if (file?.type.includes('application')) {
        obj['media_type'] = 'sheet';
      } else {
        toastError('Invalid file format');
        return;
      }

      let reader = new FileReader();
      reader.onload = (event) => {
        obj['url'] = event?.target?.result;

        if (obj['url']) {
          objArr.push(obj);
          filesArr.push(file);

          if (objArr.length === fileLis.length) {
            setFileList((pre) => ({
              files: [...filesArr, ...pre.files],
              baseFormat: [...objArr, ...pre.baseFormat],
            }));
            setValue('created_date', dayjs().format(DateFormat));
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const setUpdateData = () => {
    let defaultValues = {};
    for (const element in userData) {
      if (
        element == 'date_of_joining' ||
        element == 'date_of_birth' ||
        element == 'date_of_serviceConfirmation' ||
        element == 'date_of_exit' ||
        element == 'last_appraisal_date' ||
        element == 'created_date'
      ) {
        defaultValues[element] = userData[element]
          ? dayjs(userData[element]).format(DateFormat)
          : '';
      } else if (
        element == 'reportAuthorityID' ||
        element == 'second_approvers' ||
        element == 'first_approvers'
      ) {
        defaultValues[element] = userData[element]
          ? JSON.parse(userData[element])
          : null;
      } else if (element == 'autoCredentialSender') {
        defaultValues['defaultSendCredentials'] = userData[element];
      } else {
        defaultValues[element] = userData[element];
      }
    }

    reset({ ...defaultValues });
  };

  const previewDoc = (idx) => {
    let dataurl = fileList.baseFormat[idx];
    if (dataurl.media_type === 'image') {
      imageUrldata = dataurl.url;
      setPreviewOpen(true);
      return;
    } else if (dataurl.media_type === 'pdf') {
      pdfViewer(dataurl.url);
      imageUrldata = '';
    }
  };

  useEffect(() => {
    if (userData) {
      setUpdateData();
    }
  }, [userData]);

  const handleCheckBox = (e) => {
    setValue('first_approvers', []);
    setValue('timesheet_exemption', e.target.checked);
  };

  const handleCredentialCheckBox = (e) => {
    let name = e.target.name;
    let value = e.target.checked;

    if (name == 'allowNotification') {
      setValue('defaultSendCredentials', value);
    }

    setValue(name, value);
  };

  const deleteDocs = (idx) => {
    let clearList = fileList.baseFormat.filter((_, index) => index !== idx);
    let clearList1 = fileList.files.filter((_, index) => index !== idx);
    setTimeout(() => {
      setFileList((pre) => ({
        ...pre,
        files: [...clearList1],
        baseFormat: [...clearList],
      }));
    }, 0);
    setValue('created_date', '');
    setValue('document_name', '');
  };

  const handlePopUp = async (field, value) => {
    let object = state?.userRolesList?.filter((element) => element?.id == 2);

    if ((value === 2 || userRole == 2) && edit_id) {
      await UserRoleChangePopUp((canDelete) => {
        if (canDelete) {
          field.onChange(value);
          userRole = value;
        }
      }, object[0]?.value);
    } else {
      field.onChange(value);
    }
  };

  return (
    <div>
      <div className="d-flex  px-0 pb-1">
        <div
          className={`${style['arrow-icon']} border px-2 pb-1`}
          onClick={() => navigate('/user')}
        >
          <FaArrowLeft />
        </div>
        <div className={`mx-3 ${style['add-user']}`}>
          {edit_id ? 'Edit User' : 'Add User'}
        </div>
      </div>

      <Row className="my-2">
        <Col>
          <Card>
            <CardBody>
              <Form
                onFinish={handleSubmit(onSubmit)}
                validateTrigger="onChange"
              >
                <Row>
                  <div className={style['title-style']}>Basic Information</div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="first_name">
                        Employee First Name
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="first_name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value:
                              /^(?!.*\s{2,})(?!.*^\s)(?!.*\s$)[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;'",.<>?/\-`~\s]+$/,
                            message: 'Extra spaces are not allowed',
                          },
                          // pattern: {
                          //   value: /^[A-Za-z]+$/,
                          //   message: 'Only letters are allowed',
                          // },
                        }}
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter First Name" />
                        )}
                      />
                      {errors?.first_name && (
                        <p className="text-danger mt-1">
                          {errors?.first_name?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="last_name">
                        Employee Last Name{' '}
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="last_name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value:
                              /^(?!.*\s{2,})(?!.*^\s)(?!.*\s$)[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;'",.<>?/\-`~\s]+$/,
                            message: 'Extra spaces are not allowed',
                          },
                          // pattern: {
                          //   value: /^[A-Za-z]+$/,
                          //   message: 'Only letters are allowed',
                          // },
                        }}
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Last Name" />
                        )}
                      />
                      {errors?.last_name && (
                        <p className="text-danger mt-1">
                          {errors?.last_name?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="gender">
                        Gender
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {GENDER.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.gender && (
                        <p className="text-danger mt-1">
                          {errors?.gender?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="date_of_birth">Date of Birth</Label>
                      <Controller
                        name="date_of_birth"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            style={{ width: '100%' }}
                            format={DateFormat}
                            value={
                              field.value
                                ? dayjs(field.value, DateFormat)
                                : null
                            }
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="year_of_experience">Year of Experience</Label>
                      <Controller
                        name="year_of_experience"
                        control={control}
                        defaultValue={null}
                        rules={{
                          min: { value: 0, message: 'Invalid Number' },
                          max: { value: 75, message: 'Invalid Number' },
                        }}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                Years
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.year_of_experience && (
                        <p className="text-danger mt-1">
                          {errors?.year_of_experience?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="userRoleID">
                        User Role<span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="userRoleID"
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              handlePopUp(field, value);
                            }}
                            //onChange={(value) => field.onChange(value)}
                          >
                            {state?.userRolesList.map((element) => (
                              <Option
                                key={element?.id}
                                value={element?.id}
                                disabled={!element?.status}
                              >
                                {element?.value}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.userRoleID && (
                        <p className="text-danger">
                          {errors?.userRoleID?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="email">
                        Email
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Invalid email format',
                          },
                        }}
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Email" />
                        )}
                      />
                      {errors?.email && (
                        <p className="text-danger mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="password">Password</Label>

                      {!edit_id && (
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      )}
                      <Controller
                        name="password"
                        control={control}
                        rules={
                          !edit_id
                            ? {
                                required: 'This field is required',
                              }
                            : { required: false }
                        }
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Password" />
                        )}
                      />
                      {errors?.password && (
                        <p className="text-danger mt-1">
                          {errors.password.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <div className={style['title-style']}>
                    Contact Information
                  </div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="personal_email">
                        Personal Email
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="personal_email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Invalid email format',
                          },
                        }}
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Email" />
                        )}
                      />
                      {errors?.personal_email && (
                        <p className="text-danger mt-1">
                          {errors.personal_email.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="phone">Phone Number</Label>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\d{10}$/,
                            message: 'Phone number must be exactly 10 digits',
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            onBlur={field.onBlur}
                            placeholder="Enter Phone Number "
                          />
                        )}
                      />
                      {errors?.phone && errors.phone.type === 'pattern' && (
                        <p className="text-danger mt-1">
                          {errors.phone.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col md={4}>
                    <Form.Item>
                      <Label for="permanent_address">Permanent Address</Label>
                      <Controller
                        name="permanent_address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextArea
                            {...field}
                            placeholder="Enter Address"
                            rows={3}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <div className="d-flex align-items-center justify-content-between">
                        <Label for="residential_address" className="mr-2">
                          Residential Address
                        </Label>
                      </div>

                      <Controller
                        name="residential_address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextArea
                            {...field}
                            placeholder="Enter Address"
                            rows={3}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <FormProvider {...methods}>
                  <AddContact />
                </FormProvider>

                <Row className="mt-3">
                  <div className={style['title-style']}>Job Information</div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="date_of_joining">
                        Date of Joining
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="date_of_joining"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            style={{ width: '100%' }}
                            format={DateFormat}
                            value={
                              field.value
                                ? dayjs(field.value, DateFormat)
                                : userData?.date_of_joining
                                ? dayjs(userData?.date_of_joining, DateFormat)
                                : null
                            }
                            onChange={(date, dateString) =>
                              field.onChange(dateString)
                            }
                          />
                        )}
                      />
                      {errors?.date_of_joining && (
                        <p className="text-danger mt-1">
                          {errors?.date_of_joining?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="employeeCode">
                        Employee Code{' '}
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="employeeCode"
                        control={control}
                        rules={{ required: 'This field is required' }}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Code" />
                        )}
                      />
                      {errors?.employeeCode && (
                        <p className="text-danger mt-1">
                          {errors?.employeeCode?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="departmentID">
                        Department{' '}
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="departmentID"
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => field.onChange(value)}
                          >
                            {state?.departmentList.map((element) => (
                              <Option
                                key={element.value}
                                value={element?.value}
                                disabled={!element.status}
                              >
                                {element?.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.departmentID && (
                        <p className="text-danger mt-1">
                          {errors?.departmentID?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="designationID">
                        Designation
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="designationID"
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => field.onChange(value)}
                          >
                            {state?.designationList.map((element) => (
                              <Option
                                key={element?.value}
                                value={element?.value}
                                disabled={!element?.status}
                              >
                                {element?.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.designationID && (
                        <p className="text-danger mt-1">
                          {errors?.designationID?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col md={4}>
                    <Form.Item>
                      <Label for="reportAuthorityID">
                        Reporting Authority
                        {/* <span className="text-danger">*</span> */}
                      </Label>
                      <Controller
                        name="reportAuthorityID"
                        control={control}
                        defaultValue={null}
                        // rules={{
                        //   required: "This field is required",
                        // }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                            onChange={(value) => field.onChange(value)}
                          >
                            {state?.userAuthoriryList?.map((element) => (
                              <Option key={element.id} value={element.id}>
                                {element.value}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.reportAuthorityID && (
                        <p className="text-danger">
                          {errors?.reportAuthorityID?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="employmentType">Employment Type</Label>
                      <Controller
                        name="employmentType"
                        control={control}
                        defaultValue={EMPLOYEE_TYPE[1].value}
                        //rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => field.onChange(value)}
                          >
                            {EMPLOYEE_TYPE.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="employmentMode">Employment Mode</Label>
                      <Controller
                        name="employmentMode"
                        control={control}
                        defaultValue={null}
                        //rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => field.onChange(value)}
                          >
                            {EMPLOYEE_MODE.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="probation_period">Probation Period</Label>
                      <Controller
                        name="probation_period"
                        control={control}
                        defaultValue={null}
                        rules={{ min: { value: 0, message: 'Invalid Number' } }}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                Months
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.probation_period && (
                        <p className="text-danger mt-1">
                          {errors?.probation_period?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="notice_period">Notice Period</Label>
                      <Controller
                        name="notice_period"
                        control={control}
                        defaultValue={null}
                        rules={{ min: { value: 0, message: 'Invalid Number' } }}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                Months
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.notice_period && (
                        <p className="text-danger mt-1">
                          {errors?.notice_period?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="serviceConfirmation">
                        Service Confirmation Status
                      </Label>
                      <Controller
                        name="serviceConfirmation"
                        control={control}
                        defaultValue={null}
                        //rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => field.onChange(value)}
                          >
                            {SERVICE_STATUS.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="date_of_serviceConfirmation">
                        Date of Service Confirmation
                      </Label>
                      <Controller
                        name="date_of_serviceConfirmation"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            style={{ width: '100%' }}
                            format={DateFormat}
                            value={
                              field.value
                                ? dayjs(field.value, DateFormat)
                                : userData?.date_of_serviceConfirmation
                                ? dayjs(
                                    userData?.date_of_serviceConfirmation,
                                    DateFormat,
                                  )
                                : null
                            }
                            onChange={(date, dateString) =>
                              field.onChange(dateString)
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="contract_training_period">
                        Contract or Training Period
                      </Label>
                      <Controller
                        name="contract_training_period"
                        control={control}
                        rules={{ min: { value: 0, message: 'Invalid Number' } }}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                Months
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.contract_training_period && (
                        <p className="text-danger mt-1">
                          {errors?.contract_training_period?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="background_verification_status">
                        Background Verification Status
                      </Label>
                      <Controller
                        name="background_verification_status"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={
                              field.value ||
                              userData?.background_verification_status
                            }
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          >
                            {BG_STATUS.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <div className={style['title-style']}>
                    Enrollment Information
                  </div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="annual_CTC">
                        Annual CTC
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="annual_CTC"
                        rules={{
                          required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.annual_CTC && (
                        <p className="text-danger mt-1">
                          {errors?.annual_CTC?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="monthly_CTC">
                        Monthly CTC
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="monthly_CTC"
                        rules={{
                          required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.monthly_CTC && (
                        <p className="text-danger mt-1">
                          {errors?.monthly_CTC?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="monthly_take_home">
                        Monthly Take Home
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>
                      <Controller
                        name="monthly_take_home"
                        rules={{
                          required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.monthly_take_home && (
                        <p className="text-danger mt-1">
                          {errors?.monthly_take_home?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="PF_Amt">
                        PF Amount
                        {/* <span style={{ color: 'red', marginLeft: 2 }}>*</span> */}
                      </Label>
                      <Controller
                        name="PF_Amt"
                        rules={{
                          //required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.PF_Amt && (
                        <p className="text-danger mt-1">
                          {errors?.PF_Amt?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="esi">
                        ESI Amount
                        {/* <span style={{ color: 'red', marginLeft: 2 }}>*</span> */}
                      </Label>
                      <Controller
                        name="ESI_Amt"
                        rules={{
                          //required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.ESI_Amt && (
                        <p className="text-danger mt-1">
                          {errors?.ESI_Amt?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="esi">
                        Variable
                        {/* <span style={{ color: 'red', marginLeft: 2 }}>*</span> */}
                      </Label>
                      <Controller
                        name="variable"
                        rules={{
                          // required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.variable && (
                        <p className="text-danger mt-1">
                          {errors?.variable?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="esi">
                        Incentive
                        {/* <span style={{ color: 'red', marginLeft: 2 }}>*</span> */}
                      </Label>
                      <Controller
                        name="incentive"
                        rules={{
                          // required: 'This field is required',
                          min: { value: 0, message: 'Invalid Number' },
                        }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.incentive && (
                        <p className="text-danger mt-1">
                          {errors?.incentive?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="esi">Increment</Label>
                      <Controller
                        name="increment_amount"
                        //rules={{ required: "This field is required" }}
                        rules={{ min: { value: 0, message: 'Invalid Number' } }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.increment_amount && (
                        <p className="text-danger mt-1">
                          {errors?.increment_amount?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="esi">TDS (Annual TDS Deduction)</Label>
                      <Controller
                        name="TDS"
                        //rules={{ required: "This field is required" }}
                        rules={{ min: { value: 0, message: 'Invalid Number' } }}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <InputNumber
                            className={style.checking}
                            type="number"
                            {...field}
                            placeholder="0.00"
                            style={{ width: '100%' }}
                            suffix={
                              <span className={style['month-placehold']}>
                                INR
                              </span>
                            }
                          />
                        )}
                      />
                      {errors?.TDS && (
                        <p className="text-danger mt-1">
                          {errors?.TDS?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="last_appraisal_date">
                        Last Appraisal Date
                      </Label>
                      <Controller
                        name="last_appraisal_date"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            style={{ width: '100%' }}
                            format={DateFormat}
                            value={
                              field.value
                                ? dayjs(field.value, DateFormat)
                                : userData?.last_appraisal_date
                                ? dayjs(
                                    userData?.last_appraisal_date,
                                    DateFormat,
                                  )
                                : null
                            }
                            onChange={(date, dateString) =>
                              field.onChange(dateString)
                            }
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="UAN_number">UAN</Label>
                      <Controller
                        name="UAN_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="UAN Number" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="ESIC_number">ESIC Number</Label>
                      <Controller
                        name="ESIC_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter ESIC Number" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <div className={style['title-style']}>
                    {' '}
                    Employment Information{' '}
                  </div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="employmentStatus">
                        Employment Status
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Label>

                      <Controller
                        name="employmentStatus"
                        control={control}
                        defaultValue={EMPLOYEE_STATUS[0].value}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            type="select"
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              field.onChange(value);
                              if (value === 'WORKING') {
                                setValue('exit_status', null);
                                setValue('date_of_exit', null);
                                setValue('certificated_issue', null);
                                setValue('settlement_graduation', null);
                                setValue('settlement_eligibility', null);
                              } else if (value === 'NOTWORKING') {
                                setValue('probation', null);
                              }
                            }}
                          >
                            {EMPLOYEE_STATUS.map((option) => (
                              <Option key={option.id} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                      {errors?.employmentStatus && (
                        <p className="text-danger mt-1">
                          {errors?.employmentStatus?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  {employmentStatusValue === 'WORKING' && (
                    <Col md={4}>
                      <Form.Item>
                        <Label for="probation">
                          Probation
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Label>
                        <Controller
                          name="probation"
                          control={control}
                          defaultValue={null}
                          rules={{ required: 'This field is required' }}
                          render={({ field }) => (
                            <Select
                              type="select"
                              {...field}
                              placeholder="Select"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                            >
                              {PROBATION_STATUS.map((option) => (
                                <Option key={option.id} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </Select>
                          )}
                        />
                        {errors?.probation && (
                          <p className="text-danger mt-1">
                            {errors?.probation?.message}
                          </p>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {employmentStatusValue === 'NOT WORKING' && (
                    <>
                      <Col md={4}>
                        <Form.Item>
                          <Label for="exit_status">
                            Employment Exit Status
                          </Label>
                          <Controller
                            name="exit_status"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                              <Select
                                type="select"
                                {...field}
                                placeholder="Select"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {EMPLOYEE_EXIT_STATUS.map((option) => (
                                  <Option key={option.id} value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item>
                          <Label for="certificated_issue">
                            Certificate Issued
                          </Label>
                          <Controller
                            name="certificated_issue"
                            control={control}
                            // rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Select
                                type="select"
                                {...field}
                                placeholder="Select"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  field.onChange(value);
                                }}
                              >
                                {SERVICE_STATUS.map((option) => (
                                  <Option key={option.id} value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item>
                          <Label for="date_of_exit">Date of Exit</Label>
                          <Controller
                            name="date_of_exit"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                style={{ width: '100%' }}
                                format={DateFormat}
                                value={
                                  field.value
                                    ? dayjs(field.value, DateFormat)
                                    : userData?.date_of_exit
                                    ? dayjs(userData?.date_of_exit, DateFormat)
                                    : null
                                }
                                onChange={(date, dateString) =>
                                  field.onChange(dateString)
                                }
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={settlement_eligibility === 'ELIGIBLE' ? 2 : 4}>
                        <Form.Item>
                          <Label for="settlement_graduation">
                            Settlement Graduation
                          </Label>
                          <Controller
                            name="settlement_eligibility"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                              <Select
                                type="select"
                                {...field}
                                placeholder="Select"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  field.onChange(value);
                                  setValue('settlement_graduation', null);
                                  setValue('Amount', null);
                                }}
                              >
                                {SETTLEMENT_ELGIBILITY_STATUS.map((option) => (
                                  <Option key={option.id} value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          />
                          {errors?.settlement_eligibility && (
                            <p className="text-danger mt-1">
                              {errors?.settlement_eligibility?.message}
                            </p>
                          )}
                        </Form.Item>
                      </Col>

                      {settlement_eligibility === 'ELIGIBLE' && (
                        <Col md={2}>
                          <Form.Item>
                            <Label for="settlement_graduation">
                              Payment Status
                            </Label>
                            <Controller
                              name="settlement_graduation"
                              control={control}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  type="select"
                                  {...field}
                                  placeholder="Select"
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setValue('Amount', null);
                                  }}
                                >
                                  {SETTLEMENT_STATUS.map((option) => (
                                    <Option
                                      key={option.id}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            />
                            {errors?.settlement_graduation && (
                              <p className="text-danger mt-1">
                                {errors?.settlement_graduation?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}
                  {settlementValue === 'PAID' && (
                    <Col md={4}>
                      <Form.Item>
                        <Label for="Amount">Amount</Label>
                        <Controller
                          name="Amount"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="0.00"
                              suffix={
                                <span className={style['month-placehold']}>
                                  INR
                                </span>
                              }
                            />
                          )}
                        />
                        {errors?.Amount && (
                          <p className="text-danger mt-1">
                            {errors?.Amount?.message}
                          </p>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {settlementValue === 'NOTPAID' && (
                    <>
                      <Col md={4}>
                        {' '}
                        <div className={` ${style.forpaid}`}>
                          {/* <div
                            className={` fw-bold w-50 ${style.table_styles}`}
                          >
                            Please Be Noted
                          </div> */}
                          <small className={` p-1  fw-semibold`}>
                            ( Need To Pay Graduate to Employee)
                          </small>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mt-3 ">
                  <div className={style['title-style']}>
                    Timesheet Information
                  </div>
                  <Row className="mt-3 ">
                    <Col md={6} className="">
                      <Row>
                        <Col>
                          <Form.Item>
                            <Label for="cl_given">
                              No. of CL Given
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Controller
                              name="cl_given"
                              control={control}
                              defaultValue=""
                              rules={{
                                min: { value: 0, message: 'Invalid Number' },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  className={`${style.checking} w-100`}
                                  {...field}
                                  placeholder="0:00"
                                  type="number"
                                />
                              )}
                            />
                            {errors?.cl_given && (
                              <p className="text-danger mt-1">
                                {errors?.cl_given?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Label for="sl_given">
                              No. of SL Given
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Controller
                              name="sl_given"
                              control={control}
                              defaultValue=""
                              rules={{
                                min: { value: 0, message: 'Invalid Number' },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  className={`${style.checking} w-100`}
                                  {...field}
                                  placeholder="0:00"
                                  type="number"
                                />
                              )}
                            />
                            {errors?.sl_given && (
                              <p className="text-danger mt-1">
                                {errors?.sl_given?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>{' '}
                    <Col md={6} className=" justify-content-evenly">
                      <Row>
                        {' '}
                        <Col>
                          {' '}
                          <Form.Item>
                            <Label for="paternity_leave">
                              Paternity Leave
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Controller
                              name="paternity_leave"
                              control={control}
                              defaultValue=""
                              rules={{
                                min: { value: 0, message: 'Invalid Number' },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  className={`${style.checking} w-100`}
                                  {...field}
                                  placeholder="0:00"
                                  type="number"
                                />
                              )}
                            />
                            {errors?.paternity_leave && (
                              <p className="text-danger mt-1">
                                {errors?.paternity_leave?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          {' '}
                          <Form.Item>
                            <Label for="maternity_leave">
                              Maternity Leave
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Controller
                              name="maternity_leave"
                              control={control}
                              defaultValue=""
                              rules={{
                                min: { value: 0, message: 'Invalid Number' },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  className={`${style.checking} w-100`}
                                  {...field}
                                  placeholder="0:00"
                                  type="number"
                                />
                              )}
                            />
                            {errors?.maternity_leave && (
                              <p className="text-danger mt-1">
                                {errors?.maternity_leave?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          {' '}
                          <Form.Item>
                            <Label for="marriage_leave">
                              Marriage Leave
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Controller
                              name="marriage_leave"
                              control={control}
                              defaultValue=""
                              rules={{
                                min: { value: 0, message: 'Invalid Number' },
                              }}
                              render={({ field }) => (
                                <InputNumber
                                  className={`${style.checking} w-100`}
                                  {...field}
                                  placeholder="0:00"
                                  type="number"
                                />
                              )}
                            />
                            {errors?.marriage_leave && (
                              <p className="text-danger mt-1">
                                {errors?.marriage_leave?.message}
                              </p>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>{' '}
                  </Row>
                  <Row>
                    <div className="d-flex  align-items-start ps-1 m-0 ">
                      <div className="ps-2">
                        <Form.Item name="timesheet_exemption">
                          <Checkbox
                            name="timesheet_exemption"
                            onChange={handleCheckBox}
                            checked={watch('timesheet_exemption')}
                          />
                        </Form.Item>
                      </div>
                      <div className=" mt-1 ps-1">
                        <Label>
                          Provide a timesheet exemption for this user
                        </Label>
                      </div>
                    </div>
                  </Row>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="first_approvers">
                        1<small>st</small> approvers
                        {/* <span className="text-danger">*</span> */}
                      </Label>
                      <Controller
                        disabled={watch('timesheet_exemption')}
                        name="first_approvers"
                        control={control}
                        defaultValue={null}
                        // rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                          >
                            {state?.userAuthoriryList?.map(
                              (element) =>
                                !check_second_approvers?.includes(
                                  element.id,
                                ) && (
                                  <Select.Option
                                    key={element.id}
                                    disabled={watch('timesheet_exemption')}
                                    value={element.id}
                                  >
                                    {element.value}
                                  </Select.Option>
                                ),
                            )}
                          </Select>
                        )}
                      />
                      {errors?.first_approvers && (
                        <p className="text-danger">
                          {errors?.first_approvers?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="second_approvers">
                        2<small>nd</small> Approvers
                        {/* <span className="text-danger">*</span> */}
                      </Label>
                      <Controller
                        name="second_approvers"
                        control={control}
                        defaultValue={null}
                        disabled={
                          !check_first_approver || !check_first_approver?.length
                        }
                        // rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Select"
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                          >
                            {state?.userAuthoriryList?.map(
                              (element) =>
                                !check_first_approver?.includes(element.id) && (
                                  <Option key={element.id} value={element.id}>
                                    {element.value}
                                  </Option>
                                ),
                            )}
                          </Select>
                        )}
                      />
                      {errors?.second_approvers && (
                        <p className="text-danger">
                          {errors?.second_approvers?.message}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <div className={style['title-style']}>Other Information</div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="aadhar_number">Aadhar Number</Label>
                      <Controller
                        name="aadhar_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Aadhar Number" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    {' '}
                    <Form.Item>
                      <Label for="pan_number">Pan Number</Label>
                      <Controller
                        name="pan_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter PAN Number" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="bloodGroup">Blood Group</Label>
                      <Controller
                        name="bloodGroup"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input {...field} placeholder="Enter Blood Group" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={`${style.documentUpload} mt-3`}>
                  <div className={style['title-style']}>Document</div>
                  <Col md={4}>
                    <Form.Item>
                      <Label for="fileList">
                        Upload Document *(Pdf & Image)
                      </Label>
                      <div>
                        {/* <Upload
                          maxCount={1}
                          fileList={fileList}
                          onChange={handleImageChange}
                          onRemove={() => {
                            setFileList([]);
                            setValue("created_date", "");
                            setValue("document_name", "");
                          }}
                          
                          beforeUpload={() => false}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload> */}
                        <Button type="dashed" htmlType="button" className="p-0">
                          <label
                            style={{ cursor: 'pointer' }}
                            className="px-2 py-1 pointer"
                            htmlFor="upload"
                          >
                            <UploadOutlined /> Click to Upload
                            <input
                              multiple
                              type="file"
                              id="upload"
                              className="d-none"
                              onChange={handleImageChange}
                            />
                          </label>
                        </Button>
                        <div>
                          {fileList.baseFormat.length > 0 &&
                            fileList.baseFormat.map((item, indx) => (
                              <div
                                key={indx}
                                className="border rounded-1 p-2 mt-2 d-flex justify-content-between align-items-center"
                              >
                                <div>
                                  <img
                                    height={50}
                                    width={50}
                                    src={
                                      item['media_type'] === 'pdf'
                                        ? pdf
                                        : item['media_type'] === 'sheet'
                                        ? xlsx
                                        : item?.url
                                    }
                                    alt=""
                                    className="rounded-1"
                                  />
                                </div>
                                <div
                                  title={item?.name}
                                  className="ms-2 tmTextOverflow fw-semibold"
                                >
                                  {item?.name}
                                </div>
                                <div className="text-nowrap ms-2">
                                  <button
                                    className="btn p-0 m-0 icon-hover ms-1"
                                    onClick={() => previewDoc(indx)}
                                    type="button"
                                  >
                                    <FaRegEye size={16} />
                                  </button>
                                  <button
                                    className="btn p-0 m-0 icon-hover ms-1"
                                    type="button"
                                    onClick={() => deleteDocs(indx)}
                                  >
                                    <MdDeleteOutline size={20} />
                                  </button>
                                </div>
                              </div>
                            ))}
                          {fileList.baseFormat.length > 0 && (
                            <Image
                              wrapperStyle={{
                                display: 'none',
                              }}
                              preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) =>
                                  setPreviewOpen(visible),
                              }}
                              src={imageUrldata}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                  {fileList.baseFormat?.length != 0 &&
                    fileList.baseFormat !== null && (
                      <Col md={4}>
                        <Form.Item>
                          <Label for="document_name">Document Name</Label>
                          <Controller
                            name="document_name"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Input {...field} placeholder="Document Name" />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  {fileList.baseFormat?.length != 0 &&
                    fileList.baseFormat !== null && (
                      <Col md={4}>
                        <Form.Item>
                          <Label for="created_date">Created Date</Label>
                          <Controller
                            name="created_date"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                style={{ width: '100%' }}
                                format={DateFormat}
                                value={
                                  field.value
                                    ? dayjs(field.value, DateFormat)
                                    : null
                                }
                                onChange={(date, dateString) =>
                                  field.onChange(dateString)
                                }
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                </Row>
                <Row>
                  <div className="d-flex align-items-start ps-1 m-0">
                    <div className="ps-2">
                      <Form.Item name="allowNotification">
                        <Controller
                          control={control}
                          name="allowNotification"
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              onChange={handleCredentialCheckBox}
                              checked={watch('allowNotification')}
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="mt-1 ps-1">
                      <Label>Allow Notifications</Label>
                    </div>
                  </div>
                  <div className="d-flex align-items-start ps-1 m-0">
                    <div className="ps-2">
                      <Form.Item name="defaultSendCredentials">
                        <Controller
                          control={control}
                          name="defaultSendCredentials"
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              disabled={!watch('allowNotification')}
                              onChange={handleCredentialCheckBox}
                              checked={watch('defaultSendCredentials')}
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="mt-1 ps-1">
                      <Label>Trigger Credential email</Label>
                    </div>
                  </div>
                  <div className="d-flex align-items-start ps-1 m-0">
                    <div className="ps-2">
                      <Form.Item name="disableLogin">
                        <Controller
                          control={control}
                          name="disableLogin"
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              onChange={handleCredentialCheckBox}
                              checked={watch('disableLogin')}
                            />
                          )}
                        />
                      </Form.Item>
                    </div>
                    <div className="mt-1 ps-1">
                      <Label>Disable Login Access to the User</Label>
                    </div>
                  </div>
                </Row>
                <Row className="mt-5">
                  <Col className="d-flex justify-content-center antdBtn">
                    <Button type="default" onClick={() => navigate('/user')}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {edit_id ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AddUserForm;
