import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Col, Row } from 'reactstrap';
import { LuDownload } from 'react-icons/lu';
import TmFilter from '../../ReusableComponents/TmFilter';
import { MONTHS, YEARS } from '../../../constant/constant';
import AttendanceTable from './AttendanceTable';
import Department from '../../../Api/Department';
import Designation from '../../../Api/Designation';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Toast/ToastComponent';
import {
  dayjsToDateFormat,
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import AttendanceReport from '../../../Api/attendanceReport';
import Loader from '../../ReusableComponents/Loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAttendance } from '../../../store/reducers/actionPermission';
import { attendanceReport } from '../../ReusableComponents/downloadExcel';
import dayjs from 'dayjs';
import useFilterStateData from '../../../customHooks/useFilterStateData';

const { Search } = Input;

let setDownloadData = [];

const AttendanceComp = () => {
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  let filterInitialVal = {
    month: filterStateMonth,
    year: filterStateYear,
    date_of_joining: '',
    designation_id: '',
    department_id: '',
    username: '',
  };
  const navigate = useNavigate();
  const debounceTimeoutRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [filterList, setFilterList] = useState({
    department: [],
    designation: [],
  });

  const [form] = Form.useForm();

  const data = useSelector(getAttendance);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: filterList.department,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'department_id',
      allowClear: true,
      input: 'select',
    },
    {
      options: filterList.designation,
      optionFilterProp: 'label',
      placeholder: 'Designation',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'designation_id',
      allowClear: true,
      input: 'select',
    },
    {
      input: 'date',
      minDate: '',
      maxDate: '',
      order: 1,
      col: 2,
      defaultValue: null,
      controlName: 'date_of_joining',
      allowClear: true,
      placeholder: 'Date of joining',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleFilterChange = (obj, type) => {
    if (type === 'date') {
      obj['date_of_joining'] = obj['date_of_joining']
        ? dayjsToDateFormat(obj['date_of_joining'])
        : '';
    }
    // filterInitialVal = { ...filterInitialVal, [keys]: e };
    // filterInitialVal = { ...obj } || {};
    filterInitialVal = { ...filterInitialVal, ...obj } || {};
    getReport();
  };

  const getFilterList = async () => {
    try {
      let department = await Department.dropDown({});
      let designation = await Designation.dropDown({});
      setFilterList((prevState) => ({
        ...prevState,
        department: department?.data?.result,
        designation: designation?.data?.result,
      }));
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getReport = async () => {
    try {
      let obj = {
        ...filterInitialVal,
      };
      const response = await AttendanceReport.get(obj);
      if (response?.data?.success) {
        let initialValuesCheck = {
          report: response?.data?.result,
        };
        setDownloadData = response?.data?.result || [];
        setReportList(() => [...setDownloadData]);
        // form.setFieldsValue(initialValuesCheck);
        setLoading(true);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    getFilterList();
    getReport();
    if (data) {
      handleButtonPermission();
    }

    return () => {
      filterInitialVal = {
        month: filterStateMonth,
        year: filterStateYear,
        date_of_joining: '',
        designation_id: '',
        department_id: '',
        username: '',
      };
    };
  }, [data]);

  const handleSubmit = async () => {
    try {
      let data = form.getFieldValue('report');

      const check = data.map(
        ({
          id,
          LOPdays,
          Remarks,
          compOffDays,
          extraWorkingHours,
          onDutyDays,
          total,
          user_id,
          noMoment,
          daysMissed,
          pending,
          rejected,
          is_approved,
        }) => ({
          id,
          LOPdays,
          Remarks,
          compOffDays,
          extraWorkingHours,
          onDutyDays,
          total,
          user_id,
          noMoment,
          daysMissed,
          pending,
          rejected,
          is_approved,
        }),
      );

      if (!check?.length) {
        toastInfo('No Data Found');
        return;
      }

      const response = await AttendanceReport.update({ data: check });
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        getReport();
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  // const handleSearch = (value) => {
  //   handleFilterChange({ ...filterInitialVal, username: value }, 'select');
  //   // handleFilterChange(value, 'username', 'search');
  // };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterInitialVal, username: value });
    }, 400);
  };

  const handleDownload = () => {
    let excelData = setDownloadData || [];

    let datee = `${filterInitialVal.year}-${filterInitialVal.month}-01`;
    const month = dayjs(datee).format('MMM');
    const year = dayjs(datee).format('YYYY');
    let fileName = `Attendance Tracking ${month || ''} ${year || ''}.xlsx`;
    let downData = {
      data: excelData,
      sheetName: `${month}-${year}`,
      month: filterInitialVal?.month,
      year: filterInitialVal?.year,
      fileName,
    };
    attendanceReport(downData);
  };

  return (
    <div>
      <div className="d-md-flex">
        <div>
          <div className="">
            <div>
              <h5 className="text-nowrap me-2">Attendance Report</h5>
            </div>
          </div>
        </div>

        <div className="ms-md-auto">
          <div className="d-md-flex">
            <div className="me-md-2 ">
              {' '}
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
              />
            </div>{' '}
            <div className="mt-2 d-flex justify-content-between mt-md-0">
              <div>
                {' '}
                <Button
                  type="dashed"
                  className="me-1"
                  htmlType="button"
                  onClick={handleDownload}
                >
                  <span>
                    <LuDownload size={18} />
                  </span>
                  Download
                </Button>
              </div>
              <div>
                {buttonPermission.edit && (
                  <Button
                    type="primary"
                    size="medium"
                    htmlType="button"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div>
          <Row>
            <Col>
              <div className="mt-2">
                <TmFilter
                  filterArray={filterArray}
                  handleFilterChange={handleFilterChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Form form={form} layout="vertical">
                  <AttendanceTable form={form} report={reportList} />
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {!loading && <Loader />}
    </div>
  );
};

export default AttendanceComp;
