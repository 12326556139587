import { Button, DatePicker, Form, Select } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import style from '../../Styles/Attendance.module.css';
import { DisplayDateFormat } from '../../constant/constant';
import { useDispatch } from 'react-redux';
import {
  setColumn,
  setFilterMonth,
  setFilterYear,
} from '../../store/reducers/filterState';
import ColumnFilter from './ColumnFilter';
import { LuColumns } from 'react-icons/lu';
const TmFilter = ({
  filterArray,
  handleFilterChange,
  columnFilter,
  setColumnFilter,
  resetColumn,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  let initialValues = {};
  const [form] = Form.useForm();

  const initialValueSetter = (filterArray) => {
    filterArray?.map((item) => {
      initialValues[item.controlName] = item.defaultValue;
    });

    form.setFieldsValue(initialValues);
  };

  const setFilterLocal = (data) => {
    let getFilterLocal = sessionStorage.getItem('filterState');
    let parsed =
      getFilterLocal == undefined
        ? { month: '', year: '' }
        : JSON.parse(atob(getFilterLocal));
    sessionStorage.setItem(
      'filterState',
      btoa(JSON.stringify({ ...parsed, ...data })),
    );
  };

  const handleReset = () => {
    form.resetFields();
    let newObj = nullRemover() || {};

    if (newObj.hasOwnProperty('month')) {
      setFilterLocal({ month: '' });
      dispatch(setFilterMonth(''));
    }
    if (newObj.hasOwnProperty('year')) {
      setFilterLocal({ year: '' });
      dispatch(setFilterYear(''));
    }
    handleFilterChange(newObj);
  };

  const nullRemover = () => {
    let afterClear = form.getFieldsValue();
    let newObj = {};
    for (let obj in afterClear) {
      newObj[obj] =
        afterClear[obj] !== null && afterClear[obj] !== undefined
          ? afterClear[obj]
          : '';
    }

    return newObj;
  };

  const internalChange = (e, controlName, type) => {
    let newObj = nullRemover() || {};

    if (resetColumn) {
      dispatch(setColumn([]));
    }

    if (!!newObj['month']) {
      setFilterLocal({ month: newObj['month'] });
      dispatch(setFilterMonth(newObj['month']));
    }
    if (!!newObj['year']) {
      setFilterLocal({ year: newObj['year'] });
      dispatch(setFilterYear(newObj['year']));
    }
    handleFilterChange(newObj, type);
  };

  const handleDrawer = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    initialValueSetter(filterArray);
  }, []);

  return (
    <div className="p-1 mb-2 bg-white border">
      <Form form={form}>
        <Row className="m-0 mb-1 mb-md-0 align-items-center">
          <Col sm="auto ps-1 pe-2 mb-2">
            <b>Filter</b>
          </Col>

          {filterArray.length > 0 &&
            filterArray.map((filtr, index) => (
              <Fragment key={index + 33}>
                {(filtr.input == 'select' || filtr.input == 'date') && (
                  <Col
                    xs="6"
                    className="px-0 mb-2  my-lg-0 pe-1"
                    lg={filtr.col}
                  >
                    <Form.Item
                      name={filtr.controlName}
                      style={{ marginBottom: 0 }}
                    >
                      {filtr.input === 'select' && (
                        <Select
                          showSearch
                          allowClear={filtr.allowClear}
                          mode={filtr.mode}
                          maxTagCount={filtr.maxTagCount}
                          style={{ width: '100%' }}
                          placeholder={filtr.placeholder}
                          optionFilterProp={filtr.optionFilterProp}
                          // defaultValue={filtr.defaultValue}
                          options={filtr.options}
                          onChange={(e) =>
                            internalChange(e, filtr.controlName, filtr.input)
                          }
                        />
                      )}
                      {filtr.input === 'date' && (
                        <DatePicker
                          className={`${style.tmfiterwidthres}`}
                          minDate={filtr.minDate}
                          format={DisplayDateFormat}
                          maxDate={filtr.maxDate}
                          placeholder={filtr.placeholder}
                          // defaultValue={filtr.defaultValue}
                          allowClear={filtr.allowClear}
                          onChange={(e) =>
                            internalChange(e, filtr.controlName, filtr.input)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                {filtr.input == 'button' && (
                  <Col lg={filtr.col} className={`${filtr.className} px-0`}>
                    <Button
                      className={`   ${style.tmfiterwidthres}`}
                      type={filtr.type}
                      //disabled={disableButton()}
                      htmlType={filtr.htmlType}
                      onClick={handleReset}
                    >
                      {/* {filtr.text} */}
                      Clear Filter
                    </Button>
                  </Col>
                )}
              </Fragment>
            ))}
          {columnFilter && columnFilter.length > 0 && (
            <Col className={` px-0 text-end`}>
              <Button
                title="Column Filter"
                className={`   ${style.tmfiterwidthres}`}
                onClick={handleDrawer}
              >
                <LuColumns size={20} />
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      {columnFilter && columnFilter.length > 0 && (
        <ColumnFilter
          onClose={handleDrawer}
          visible={visible}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
        />
      )}
    </div>
  );
};

export default TmFilter;
