import { useSelector } from 'react-redux';
import { getfilterState } from '../store/reducers/filterState';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export default function useFilterStateData() {
  const filterStateData = useSelector(getfilterState);

  return {
    month:
      filterStateData['month'] ||
      new Date().toLocaleString('en-US', {
        month: 'long',
      }),
    year: filterStateData['year'] || dayjs().year(),
  };
}
