import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import style from '../../Styles/LeaveMangment.module.css';
import { Col, Row } from 'reactstrap';
import TmFilter from '../ReusableComponents/TmFilter';
import {
  LEAVE_STATUS,
  LEAVETYPE,
  MONTHS,
  MONTHS_INDEX,
  PERMISSION_PAGE_ROUTES,
  PERMISSION_TYPE,
  STATUS,
} from '../../constant/constant';
import LeaveManagementTable from './LeaveManagementTable';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline, MdOutlineComment } from 'react-icons/md';
import ModalComponent from '../ReusableComponents/ModalComponent';
import ApplyLeaveComponent from './ApplyLeaveComponent';
import ApplyPermission from './ApplyPermission';
import CommentModal from './CommentModal';
import {
  myRequestLeaveHeaders,
  myRequestPermissionHeaders,
} from '../../constant/tableHeader';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import ActionButton from '../ReusableComponents/ActionButton';
import { useAuth } from '../../Context/AuthContext';
import { toastError, toastInfo, toastSuccess } from '../Toast/ToastComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import dayjs from 'dayjs';
import {
  dayjsToDateFormat,
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
  GET_CURRENT_MONTH_IDX,
} from '../../ReusableFn/reusableFn';
import Loader from '../ReusableComponents/Loader';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import { useSelector } from 'react-redux';
import {
  getMyLeave,
  getMyPermission,
} from '../../store/reducers/actionPermission';
import { Permissions } from '../../Api/Permisisons';
import useFilterStateData from '../../customHooks/useFilterStateData';

let modalHeader, modalData;

const MyRequests = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [modal, setModal] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [loading, setLoading] = useState(false);

  const [buttonPermission, setButtonPermissions] = useState({});

  const [leaveList, setLeaveList] = useState({
    list: [],
    details: undefined,
  });

  const [isPermissionComp, setIsPermissionComp] = useState(false);
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  let filterValue = {
    type: '',
    leave_status: '',
    date: '',
    month: filterStateMonth,
    year: filterStateYear,
  };
  const data = useSelector(
    PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
      ? getMyPermission
      : getMyLeave,
  );

  const filterArray = [
    {
      options: PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? PERMISSION_TYPE
        : LEAVETYPE,
      optionFilterProp: 'label',
      placeholder: PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? 'Permission Type'
        : 'Leave Type',
      order: 1,
      col: 2,
      mode: 'multiple',
      maxTagCount: 1,
      defaultValue: null,
      controlName: 'type',
      allowClear: true,
      input: 'select',
    },
    {
      options: LEAVE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'leave_status',
      allowClear: true,
      input: 'select',
    },
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: true,
      input: 'select',
    },
    {
      options: generateYears(),
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: true,
      input: 'select',
    },

    {
      input: 'date',
      minDate: '',
      maxDate: '',
      order: 1,
      col: 2,
      defaultValue: null,
      controlName: 'date',
      allowClear: true,
      placeholder: 'DD-MM-YYYY',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const UserDetailsCard = [
    {
      label: 'Available',
      number: leaveList?.details?.available,
      value: 1,
      className: 'alert-primary',
      isMulti: true,
      casualLeave: leaveList?.details?.availableCL,
      sickLeave: leaveList?.details?.availableSL,
    },

    {
      label: 'Balance',
      number: leaveList?.details?.balance,
      value: 4,
      className: 'alert-secondary',
      isMulti: true,
      casualLeave: leaveList?.details?.balanceCL,
      sickLeave: leaveList?.details?.balanceSL,
    },
  ];

  const RequestCard = [
    {
      label: 'Approved',
      number: leaveList?.details?.approved,
      value: 2,
      className: 'alert-success',
      isMulti: false,
    },
    {
      label: 'Pending',
      number: leaveList?.details?.pending,
      value: 3,
      className: 'alert-warning',
      isMulti: false,
    },

    {
      label: 'Rejected',
      number: leaveList?.details?.rejected,
      value: 4,
      className: 'alert-danger',
      isMulti: false,
    },
  ];

  const { authState } = useAuth();

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const getIsPermission = async () => {
    try {
      setIsPermissionComp(
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) ? true : false,
      );
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    getLeaveManageList();
    if (data) {
      handleButtonPermission();
    }
    return () => {
      setModal(false);
      setShowComment(false);
      setLoading(false);
      setButtonPermissions({});
      setIsPermissionComp(false);
      setLeaveList({
        list: [],
        details: undefined,
      });
      filterValue = {
        type: null,
        leave_status: null,
        date: '',
        month: filterStateMonth,
        year: filterStateYear,
      };
    };
  }, [data, pathname]);

  const getLeaveManageList = async () => {
    try {
      let obj = {
        ...filterValue,
        user_id: authState?.id,
      };

      //const response = await LeaveManageApi.get(obj);

      let response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? await Permissions.get(obj)
        : await LeaveManageApi.get(obj);

      if (response?.data?.success) {
        setLoading(true);
        setLeaveList((prev) => ({
          ...prev,
          list: response.data.result[
            PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
              ? 'permissions'
              : 'leaves'
          ],
          details: response.data.result.details,
        }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleEdit = (e) => {};

  const handleFilterChange = (obj, type) => {
    if (type === 'date') {
      obj['date'] = obj['date'] ? dayjsToDateFormat(obj['date']) : '';
    }
    filterValue = { ...obj } || {};
    // filterValue = { ...filterValue, [keys]: e };
    getLeaveManageList();
  };

  const toggleModal = (type, value) => {
    if (value && type === 'Edit') {
      modalData = value;
    } else if (type === 'Add') {
      setShowComment(false);
      modalData = {};
    }

    modalHeader = (
      <span className="text-nowrap">{`${type} ${
        PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
          ? 'Permission'
          : 'Leave'
      } `}</span>
    );
    setModal(!modal);
  };

  const handleEditData = async (id) => {
    try {
      const response = PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? await Permissions.getById(id)
        : await LeaveManageApi.getById(id);

      if (response?.data?.success) {
        toggleModal('Edit', response?.data?.result[0]);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const callDelete = async (id, requestType) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = PERMISSION_PAGE_ROUTES.includes(
            pathname.split('/')[1],
          )
            ? await Permissions.delete({
                id,
                requestType,
              })
            : await LeaveManageApi.delete({
                id,
                requestType,
              });
          if (response.data.success) {
            toastSuccess(response?.data?.message);
            getLeaveManageList();
          } else {
            toastInfo(response.data.message);
          }
        } catch (error) {
          toastError(error.response.data.message);
        }
      }
    });
  };

  const handleClick = async (id, type) => {
    if (type === 'View') {
      PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
        ? navigate(
            `/my_requests_permission/view/${btoa(id)}/${btoa(
              'myRequest',
            )}/${btoa(JSON.stringify(buttonPermission))}`,
          )
        : navigate(
            `/my_requests/view/${btoa(id)}/${btoa('myRequest')}/${btoa(
              JSON.stringify(buttonPermission),
            )}`,
          );
    } else if (type === 'Edit' || type === 'Comment') {
      setShowComment(type === 'Comment' ? true : false);
      await handleEditData(id);
    } else if (type == 'Delete') {
      callDelete(id, 'myRequest');
    }
  };

  const actions = (
    <span>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <FiEdit size={18} />
      </button>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <MdDeleteOutline size={20} />
      </button>
      <button className="icon-hover btn p-0" onClick={handleEdit}>
        <MdOutlineComment size={18} />
      </button>
    </span>
  );

  return (
    <div className={`${style.leaveManage}`}>
      <div className="d-md-flex   ">
        <div>
          <h5 className="">My Requests</h5>
        </div>
        <div className="ms-md-auto pt-2 pt-md-0 mt-md-0">
          {buttonPermission?.apply && (
            <Button
              className={`${style.forapplyLeave}`}
              type="primary"
              htmlType="button"
              onClick={() => toggleModal('Add', null)}
            >
              {/* <MdAdd size={20} className="p-0 m-0" /> */}
              Apply{' '}
              {PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
                ? 'Permission '
                : 'Leave'}
            </Button>
          )}
        </div>
      </div>

      {loading && (
        <div>
          <div className="d-flex leaveStatus align-items-center  justify-content-start mt-3 mx-0 m-0 p-0 flex-wrap">
            {/* {UserDetailsCard.map((itm, index) => (
              <div className="p-0 mt-1 border rounded-1 bg-white">
                <div className="d-flex justify-content-center">
                  <span className="col-auto mt-1 pt-1 fw-semibold">
                    {itm.label} <span className="">({itm.number})</span>
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="d-flex align-items-center">
                    <span className="fw-bold">CL</span>
                    <div
                      className={`alert text-center m-2 py-1 rounded-1 border-0 fw-semibold ${itm.className}`}
                    >
                      <span className="">{itm.casualLeave}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="fw-bold">SL</span>
                    <div
                      className={`alert text-center m-2 py-1 rounded-1 border-0 fw-semibold ${itm.className}`}
                    >
                      <span className="">{itm.sickLeave}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}

            {RequestCard.map((itm, index) => (
              <div
                key={index}
                className={`p-3 border  py-3 mt-md-1 mt-2  rounded-1 bg-white me-md-2 ${style.forapplyLeave}`}
              >
                <div className=" row m-0 p-0 ">
                  <div
                    className={`col-3    text-center align-items-center alert  m-0 p-2  rounded-1 border-0 fw-semibold ${itm.className}`}
                  >
                    <span className="fs-5">{itm.number}</span>
                  </div>
                  <div className="col-auto mt-1  align-items-start pt-2 row m-0 fw-semibold">
                    {itm.label}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <Row>
              <Col className="mt-3" sm={12}>
                <div>
                  <TmFilter
                    filterArray={filterArray}
                    handleFilterChange={handleFilterChange}
                  />
                </div>
              </Col>
              <Col>
                <LeaveManagementTable
                  buttonPermission={buttonPermission}
                  columns={
                    PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1])
                      ? myRequestPermissionHeaders
                      : myRequestLeaveHeaders
                  }
                  rows={leaveList.list}
                  handleClick={handleClick}
                  tableCustom={{
                    columnCtrl: ['action'],
                    hideBtn: {
                      edit: buttonPermission?.edit,
                      delete: buttonPermission?.delete,
                      comment: buttonPermission?.comment,
                    },
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <ModalComponent
              size="md"
              toggleModal={() => toggleModal('Add', null)}
              modalOpen={modal}
              modalHeader={!showComment ? modalHeader : ''}
              modalBody={
                !showComment ? (
                  PERMISSION_PAGE_ROUTES.includes(pathname.split('/')[1]) ? (
                    <ApplyPermission
                      modalData={modalData}
                      getLeaveManageList={getLeaveManageList}
                      toggleModal={toggleModal}
                    />
                  ) : (
                    <ApplyLeaveComponent
                      modalData={modalData}
                      getLeaveManageList={getLeaveManageList}
                      toggleModal={toggleModal}
                    />
                  )
                ) : (
                  <CommentModal
                    modalData={modalData}
                    getLeaveManageList={getLeaveManageList}
                    toggleModal={toggleModal}
                  />
                )
              }
            />
          </div>{' '}
        </div>
      )}
      {!loading && <Loader />}
    </div>
  );
};

export default MyRequests;
